<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Настал этот невероятный момент, мы начинаем лепить лицо )</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Шкурки жесткой зернистости: армированная сетка, напильник, шкурка на тканной основе</li>
                <li>Шкурки средней зернистости</li>
                <li>Следок (обычный женский)</li>
                <li>Стеки</li>
                <li>Макетный нож</li>
                <li>Циркуль, прозрачная линейка с разметкой</li>
            </ul>
            <h3>1. Дорабатываем голову</h3>
        </div>

        <VideoView video-id="4dfdf62e9e01480895d5c39c28cb2370"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Вставляем заготовку в трафарет, намечаем, что убрать, что нарастить</li>
                <li>Вышкуриваем срезы</li>
                <li>Корректируем заготовку по трафартеу. Сначала по-сухому (убираем все ненужное), потом по-сырому (добавляем пластик)</li>
                <li>ВАЖНО! Проверям на симметрию! От этого будет зависеть правильная установка глаз!</li>
            </ol>
            
            <h3>2. Лепим лопатки</h3>
        </div>

        <VideoView video-id="f6bd1a1609d2494ba431db40c89c1807"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Проверяем заготовку по трафарету</li>
                <li>Отшкуриваем ключицу и грудь, убираем все углы</li>
                <li>Восстанавливаем разметку</li>
                <li>Лепим прямоугольник толщиной 2мм, высотой и шириной с намеченную лопатку</li>
                <li>Разрезаем его по диагонали</li>
                <li>Накладываем треугольник на место расположения лопатки и мягко примазываем</li>
                <li>Проверяем лопатки на симметрию объемов, по высоте, по ширине, по наклону</li>
            </ol>

            <h3>3. Вставляем глаза</h3>
        </div>

        <VideoView video-id="a00196d0924645cfb99cdde51bb345f0"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Шкурим заготовку головы</li>
                <li>Отмечаем центры по трафарету, проверьте, чтобы линии были перпендикулярны. Дополнительно проверьте центры с помощью циркуля или линейки.</li>
                <li>Подготовим полусферы. Сошкурьте юбочку, соедините шарики вместе, проверьте, чтобы они создавали шар и были одинаковыми. Нанесите основные центральные линии.</li>
                <li>Измерям полусферу, к примеру получилось 12мм. Помним, что расстояние между глаз — один глаз. Значит от центра откладываем по 6мм (12 : 2 = 6). Если хотите можете слегка увеличить это расстояние, но не уменьшайте!</li>
                <li>ВАЖНО! Глаза располагаются ниже горизонтальной линии середины. Кладем глаз ниже горизонтальной линии середины, обводим.</li>
                <li>Вырезаем отверстие под глаз, НЕ заходя за горизотальную линию середины и вертикальную линию (расстояния между глаз)</li>
                <li>Примеряем глаз, чтобы он входил в отверстие. При виде сбоку он НЕ должен выходить за границы лба.</li>
                <li>Если все устраивает, выкладываем колбаску пластика изнутри и фиксируем глаз. Помним, что линии на глазах должны быть параллельными разметке на голове!</li>
                <li>Рисуем подобие зрачка в центре глаза и и добиваемся того, чтобы глаз смотрел вам на переносицу.</li>
                <li>Тоже самое повторяем со вторым глазом.</li>
                <li>Проверьте. Оба глаза должны смотреть в одну точку, вам на переносицу, а также они должны быть одинаково утоплены в голову.</li>
            </ol>
            
            <a @click="showImage('/files/BodoirV2/EyeInsert.jpg', 'swal-image2')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-eyeinsert.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>4. Дорабатываем грудь</h3>
        </div>

        <VideoView video-id="2c9932e422bb40c9beec4b9a38eaa1fd"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <p>Дорабатываем объемы на грудной клетке по центру, по бокам, у подмышечной впадины</p>

            <h3>5. Голова. Разметка</h3>
        </div>

        <VideoView video-id="789f0d3fc16f4313b59f5e7fa54b68cd"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>От линии середины головы поднимаемся вверх и отмечаем линию бровей</li>
                <li>Расстояние от линии бровей до конца головы делим на 2 — это линия носа</li>
                <li>Расстояние от линии носа до конца головы делим на 2 — это середина нижней губы</li>
                <li>Измеряем расстояние от линии бровей до носа и откладываем вверх от линии бровей — линия роста волос.</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/Layout.jpg', 'swal-image2')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-layout.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>6. Голова. Верхнее веко</h3>
        </div>

        <VideoView video-id="7b2ee2c204a14087a68fa210898aca4a"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Намечаем располажение уголков глаза. Внутренние углы располагаются ниже середины глаза. Внешние углы располагаются на линии середины глаза.</li>
                <li>Заполняем пустоты в глазницах</li>
                <li>Выкладываем верхнее веко от одного угла до другого</li>
                <li>Мягко примазываем, продавливам пластик так, чтобы он начал повторять форму глаза.</li>
                <li>Формируем красивый изгиб века</li>
                <li>Повторяем тоже на втором глазу</li>
                <li>Проверяем на симметрию</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/Eye.jpg', 'swal-image2')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-eye.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>6. Голова. Нижнее веко</h3>
        </div>

        <VideoView video-id="deeb1eef3aa54f1f905fd7bd14726078"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Доработать форму головы по трафарету</li>
                <li>Вставить глаза по разметке</li>
                <li>Слепить верхнее и нижнее веко</li>
                <li>Слепить лопатки</li>
                <li>Доработать форму тела</li>
                <li><b>Подготовить материалы</b> к следующему занятию: тканная перчатка вашего размера (чтобы пальцы были туго обтянуты, а не висели мешки ткани)</li>
            </ol>
            <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
           <h3>Время выполнения: 2,5 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>